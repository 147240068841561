<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <nav_bar />
        <div class="bg"></div>
      </b-col>
    </b-row>

    <b-row style="margin-top: 4%">
      <b-col style="padding: 0">
        <div class="wrapper">
          <div class="avatar">
            <div class="textav">
              <h3>Foto Profil</h3>
              <span>Unggah file dengan format jpeg/png</span>
            </div>
            <div class="imgav">
              <input
                type="file"
                ref="file"
                @change="upload"
                style="display: none"
              />
              <img
                v-if="!statePict"
                @click="$refs.file.click()"
                src="../../assets/icon/Group5.svg"
                alt=""
                style="margin-left: 10%; cursor: pointer"
              />
              <b-avatar
                v-else
                rounded="lg"
                button
                @click="$refs.file.click()"
                size="10em"
                :src="statePict"
              >
              </b-avatar>
            </div>
          </div>

          <div class="form">
            <div class="input">
              <span class="label">Nama Lengkap</span>
              <b-skeleton
                class="inputs"
                v-if="stateProfile == ''"
                type="input"
                animation="fade"
              ></b-skeleton>
              <b-form-input
                v-else
                v-model="stateProfile.nama"
                type="text"
                class="inputs"
              ></b-form-input>
            </div>

            <div class="input">
              <span class="label">Alamat</span>
              <b-skeleton
                class="inputs"
                v-if="stateProfile == ''"
                type="input"
                animation="fade"
              ></b-skeleton>
              <b-form-input
                v-else
                v-model="stateProfile.alamat"
                type="text"
                style="text-transform: none"
                class="inputs"
              ></b-form-input>
            </div>

            <div class="input">
              <span class="label">Nomor Telepon</span>
              <b-skeleton
                class="inputs"
                v-if="stateProfile == ''"
                type="input"
                animation="fade"
              ></b-skeleton>
              <b-form-input
                v-else
                v-model="stateProfile.noHp"
                type="number"
                class="inputs"
              ></b-form-input>
            </div>

            <div class="input">
              <span class="label">Password Lama</span>
              <b-skeleton
                class="inputs"
                v-if="stateProfile == ''"
                type="input"
                animation="fade"
              ></b-skeleton>
              <b-form-input
                style="text-transform: none"
                v-else
                v-model="passwordLama"
                type="password"
                class="inputs"
              ></b-form-input>
            </div>

            <div class="input">
              <span class="label">Password Baru</span>
              <b-skeleton
                class="inputs"
                v-if="stateProfile == ''"
                type="input"
                animation="fade"
              ></b-skeleton>
              <b-form-input
                style="text-transform: none"
                v-else
                v-model="passwordBaru"
                type="password"
                class="inputs"
              ></b-form-input>
            </div>

            <div class="input">
              <span class="label">Email</span>
              <b-skeleton
                class="inputs"
                v-if="stateProfile == ''"
                type="input"
                animation="fade"
              ></b-skeleton>
              <b-form-input
                style="text-transform: none"
                v-else
                v-model="stateProfile.email"
                type="text"
                class="inputs"
              ></b-form-input>
            </div>

            <div class="input">
              <span class="label">Tempat Lahir</span>
              <b-skeleton
                class="inputs"
                v-if="stateProfile == ''"
                type="input"
                animation="fade"
              ></b-skeleton>
              <b-form-input
                v-else
                v-model="stateProfile.tempatLahir"
                type="text"
                class="inputs"
              ></b-form-input>
            </div>

            <div class="input">
              <span class="label">Tanggal Lahir</span>
              <b-skeleton
                class="inputs"
                v-if="stateProfile == ''"
                type="input"
                animation="fade"
              ></b-skeleton>
              <b-form-input
                v-else
                style="text-transform: none"
                class="inputs"
                id="profile.tanggalLahir"
                v-model="stateProfile.tanggalLahir"
                type="date"
                autocomplete="off"
              ></b-form-input>
            </div>

            <div class="input">
              <span class="label">Nomor KTP</span>
              <b-skeleton
                class="inputs"
                v-if="stateProfile == ''"
                type="input"
                animation="fade"
              ></b-skeleton>
              <b-form-input
                v-else
                v-model="stateProfile.noKTP"
                type="number"
                class="inputs"
              ></b-form-input>
            </div>

            <div class="input">
              <span class="label">Pendidikan Terakhir</span>
              <b-skeleton
                class="inputs"
                v-if="stateProfile == ''"
                type="input"
                animation="fade"
              ></b-skeleton>
              <b-form-input
                v-else
                v-model="stateProfile.pendidikanTerakhir"
                type="text"
                class="inputs"
              ></b-form-input>
            </div>

            <div class="pesan">
              <span>{{ pesan }}</span>
            </div>

            <div class="button-wrapper">
              <b-button @click="doEdit" v-if="loadingEdit == false"
                >Simpan Perubahan</b-button
              >
              <b-button v-else><white-spinner /></b-button>
            </div>

            <div class="modal">
              <b-modal
                hide-footer
                hide-header
                centered
                id="modal-success"
                title="Logout"
              >
                <div
                  class="contents"
                  style="display: flex; align-items: center;justify-content;center; padding:1% 4%;"
                >
                  <span
                    style="
                      font-family: 'nunito', sans-serif;
                      font-size: 28px;
                      font-weight: 700;
                      color: #061737;
                    "
                    >Profile Anda berhasil diubah</span
                  >
                  <img src="../../assets/vector/checked.svg" alt="" />
                </div>

                <div
                  class="button-wrapper"
                  style="display: flex; width: 100%; justify-content: center"
                >
                  <b-button
                    @click="() => this.$router.push('/main_dashboard')"
                    style="
                      background-color: #327ff2;
                      color: #fff;
                      font-family: 'nunito', sans-serif;
                      font-weight: 700;
                      width: 25%;
                      border: none;
                      margin-top: 20px;
                      height: 40px;
                    "
                    >Ya</b-button
                  >
                </div>
              </b-modal>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "@/ip_config";
import { mapState } from "vuex";
import whiteSpinner from "../../components/spinner/white-spinner.vue";
import avaSpinner from "../../components/spinner/ava-spinner.vue";
import BigBlueSpinner from "../../components/spinner/big-blue-spinner.vue";
import Nav_bar from "../../components/nav_bar.vue";

export default {
  components: { whiteSpinner, avaSpinner, BigBlueSpinner, Nav_bar },
  data() {
    return {
      profile: [],
      pesan: "",
      imgSrc: "",
      loadingAva: false,
      loadingEdit: false,
      passwordLama: "",
      passwordBaru: "",
    };
  },

  computed: {
    ...mapState("apiUser", ["stateProfile", "statePict"]),
  },

  created() {
    this.$store.dispatch("apiUser/getUser");
  },

  methods: {
    async doEdit() {
      let vm = this;
      vm.loadingEdit = true;
      if (vm.passwordLama && vm.passwordBaru) {
        let changePass = await axios.post(
          "users/changePassword",
          {
            passwordLama: vm.passwordLama,
            passwordBaru: vm.passwordBaru,
          },
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        );
        if (changePass.data.message == "password berhasil di rubah") {
          vm.loadingEdit = false;
          this.$root.$emit("bv::show::modal", "modal-success");
        }
      } else {
        if (vm.stateProfile.tanggalLahir == "") {
          vm.pesan = "Tanggal Lahir Harus Diisi";
        } else {
          vm.loadingEdit = true;
          await axios.post(
            "users/editProfile",
            {
              nama: vm.stateProfile.nama,
              alamat: vm.stateProfile.alamat,
              noHp: vm.stateProfile.noHp,
              email: vm.stateProfile.email,
              tempatLahir: vm.stateProfile.tempatLahir,
              tanggalLahir: vm.stateProfile.tanggalLahir,
              noKTP: vm.stateProfile.noKTP,
              pendidikanTerakhir: vm.stateProfile.pendidikanTerakhir,
            },
            {
              headers: {
                token: localStorage.getItem("token"),
              },
            }
          );
          vm.loadingEdit = false;
          this.$root.$emit("bv::show::modal", "modal-success");
        }
      }
    },
    upload() {
      let vm = this;
      vm.loadingAva = true;
      let x = localStorage.getItem("token");
      let formData = new FormData();
      formData.append("picture", this.$refs.file.files[0]);
      axios
        .post("users/changePP", formData, {
          headers: {
            token: x,
          },
        })
        .then(() => {
          this.$store.dispatch("apiUser/getUser");
          vm.loadingAva = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
);

.wrapper {
  display: flex;
  padding: 0;
  justify-content: center;
  background-color: map-get($color, "blue") !important;
  flex-direction: column;
  align-items: center;
  .avatar {
    margin: 5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .textav {
      width: 35%;
      font-family: "nunito", sans-serif;
      color: map-get($color, "white");
      font-weight: 700;
    }
  }
  .form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: map-get($color, "white");

    align-items: center;
    .input {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 70%;
      color: map-get($color, "white");

      .label {
        width: 15%;
        font-family: "nunito", sans-serif;
        font-weight: 700;
      }
      .inputs {
        height: 57px;
        font-family: "nunito", sans-serif;
        text-transform: capitalize;
        font-weight: 700;
        font-size: 16px;
        margin: 1% 0;
        border-radius: 6px;
        display: flex;
        align-items: center;
        width: 30%;
        color: map-get($color, "white");
        background-color: map-get($color, "blue");
      }
    }

    .button-wrapper {
      width: 44%;
      margin-top: 3%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .btn {
        width: 30%;
        height: 57px;
        font-family: "nunito", sans-serif;
        font-weight: 700;
        text-transform: capitalize;
        font-size: 16px;
        border-radius: 6px;
        background-color: map-get($color, "white");
        color: map-get($color, "blue");
        border: 1px solid map-get($color, "blue");
        box-shadow: 0px 20px 25px -10px rgba(15, 93, 210, 0.25);
        margin-bottom: 3%;
      }
    }
  }
}
</style>
